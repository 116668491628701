import React, { Fragment } from 'react'
import { Global } from '@emotion/core'
import { globalStyles } from '../components/layout.styles'
import ScrollAnim from 'rc-scroll-anim'
import SEO from "../components/seo"
import NavBar from '../components/NavBar'
import OlimpiadaPoket1984 from '../components/OlimpiadaPoket1984'
import Footer from '../components/Footer'
import Calendar from '../images/calendar.svg'
import ArrowDown from '../images/arrowDown.png'
import Selo from '../images/selo.png'

import { 
  header,
  bg3,
  btn3,
  title3,
  arrow
} from '../components/styles/InternalPages.styles'

const StudentsPage = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Olimpíada Pocket 1984 - George Orwell" />
    <NavBar location={props.location} />
    <div css={header}>
      <div css={bg3} style={{ marginTop: 84 }}>
        <div style={{ paddingTop: 40, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection:'column' }}>
          <img src={Selo} alt='Selo' style={{ marginBottom: 32 }} />
          <div css={title3}>1984 - George Orwell</div>
          <h2><img src={Calendar} alt='calendar' /> 09/10 a 25/10</h2>
          <h1>O GRANDE IRMÃO<br /> ESTÁ ESPERANDO.</h1>
          <a href='https://fractalid.fractaltecnologia.com.br/signup?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' css={btn3}>ACESSE A PROVA</a>
          <ScrollAnim.Link
            to='aplicacao'
            offsetTop='120'
          >
            <div css={arrow} style={{ marginTop: 190 }}>
              Saiba mais<br />
              <img src={ArrowDown} alt='/' />
            </div>
          </ScrollAnim.Link>
        </div>
      </div>
      <div id='aplicacao' />
      <OlimpiadaPoket1984 />
    </div>
    <Footer />
  </Fragment>
)

export default StudentsPage
