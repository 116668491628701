import React, { Fragment } from 'react'
import Ios from '../images/ios.svg'
import Android from '../images/android.png'
import {
  inspired,
  container,
  contentView,
  columns, 
  column,
  visionBox,
  titlesApp,
  titleApp,
  ctaApp,
  linksApps
} from './styles/EstudeAqui.styles'

import {
  btn3,
  bg7
} from '../components/styles/InternalPages.styles'

const InspiredSite = props => (
  <Fragment>
    <div css={inspired}>
      <div css={container}>
        <div css={visionBox}>
          <div css={columns}>
            <div css={column}>
              <div css={contentView}>
                <h2>
                  POCKET<br /> 1984
                </h2>
                <p style={{ marginBottom: 36 }}>No pós-guerra, George Orwell escreveu o que viria a se tornar o mais célebre romance distópico: 1984. A inversão da data em relação ao ano que o redigiu (1948) era um alerta (e uma licença poética) sobre os riscos futuros de determinados regimes políticos. No caso, Orwell buscava retratar os horrores causados pelos totalitarismos (nazismo e stalinismo) através de uma sociedade fictícia na qual o governo exerce controle total sobre os indivíduos, retirando-lhes sua liberdade de pensamento, de ir e vir, de desejar. Desde então, 1984 tornou-se um best-seller, adorado por diferentes gerações, praticamente um tratado político sobre todas as ameaças à democracia que podem vir a ocorrer em uma sociedade. </p>
                <p style={{ marginBottom: 36 }}>A Olimpíada Pocket convida todos os apaixonados por essa obra marcante, seja por seu aspecto literário, ou por sua força como metáfora política ou social, a aprenderem um pouco mais sobre estilo da escrita orwelliana, panorama histórico da criação da obra, problemáticas políticas históricas e atuais levantadas por ela, inserção do romance na tradição distópica e todos os possíveis matizes de interpretação e recepção deste clássico. </p>
                <a href='https://fractalid.fractaltecnologia.com.br/signup?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' css={btn3} style={{ margin: '0 auto' }}>ACESSE A PROVA</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {false && <section css={bg7} style={{ flexDirection: 'column' }}>
      <div css={titlesApp}>
        <div css={ctaApp}>NOSSO APP</div>
        <div css={titleApp}>Baixe o app das Olimpíadas Pocket e faça sua prova.</div>
      </div>
      <div css={linksApps}>
        <a href='/'><img src={Ios} alt='/' /></a>
        <a href='/'><img src={Android} alt='/' /></a>
      </div>
    </section>}
  </Fragment>
)

export default InspiredSite
